<script setup>
import getCurrentLanguage from '@/helpers/getCurrentLanguage.js'
import key from 'keymaster'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { container as WidgetContainerModal } from 'jenesius-vue-modal'
import { setI18nLanguage } from '@/helpers/i18n.js'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'
import { useStorage } from '@vueuse/core'

const Debugger = defineAsyncComponent(() => import('@/components/Debug/DebuggerTool.vue'))
const LayoutAuth = defineAsyncComponent(() => import('@/components/Layouts/LayoutAuth.vue'))
const LayoutNone = defineAsyncComponent(() => import('@/components/Layouts/LayoutNone.vue'))
const LayoutNormal = defineAsyncComponent(() => import('@/components/Layouts/LayoutNormal.vue'))

const APP_NAME = import.meta.env.VITE_APP_NAME
const debuggerVisible = useStorage('debugger-visible', false)
const i18n = useI18n()
const route = useRoute()

useMeta({ title: 'Home', titleTemplate: '%s' })

const layout = ref(route?.meta?.layout ?? 'normal')
const layouts = {
	auth: LayoutAuth,
	none: LayoutNone,
	normal: LayoutNormal
}

const layoutComponent = computed(() => { return layouts[layout.value] })

watch(route, () => {
	layout.value = route?.meta?.layout ?? 'normal'
	window._paq.push([ 'setCustomDimension', 1, window.innerWidth ])
	window._paq.push([ 'setCustomDimension', 2, window.innerHeight ])
}, { immediate: true })

watch(i18n.locale, language => {
	i18n.locale.value = language
	localStorage.language = language
	document.querySelector('html').setAttribute('lang', language)
})

onMounted(() => {
	const language = getCurrentLanguage()
	setI18nLanguage(language)

	key('ctrl+alt+shift+c', () => {
		debuggerVisible.value = true
		return false
	})
})
</script>

<template>
	<div id="app">
		<metainfo>
			<template #title="{ content, metainfo }">
				{{
					metainfo.titleTemplate
						? `${metainfo.titleTemplate.replace('%s', content)} - ${APP_NAME}`
						: `${content} - ${APP_NAME}`
				}}
			</template>
		</metainfo>
		<component
			:is="layoutComponent"
			:key="layoutComponent"
		>
			<router-view />
		</component>

		<Debugger v-if="debuggerVisible" />
		<widget-container-modal />
	</div>
</template>

<style lang="scss" src="./styles/App.scss"></style>
